export const vehicleClosingOptionsEnum = {
  NO_CLOSING: 1,
  // NACIONAL
  CLOSING_SA: 2,
  // INTERNACIONAL
  CLOSING_LLC: 3,
};

export const vehicleNationality = {
  NATIONAL: 1,
  INTERNATIONAL: 2,
};
